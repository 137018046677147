import auth from "./middleware/auth";
import verified from "@/router/middleware/verified";
import email_verified from "@/router/middleware/email_verified";
import ondato_verified from "@/router/middleware/ondato_verified";

export default [
    {
        path: "/messages",
        name: "user.own.messages",
        component: () => import("../views/app/user/Messages"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/messages/:id",
        name: "user.own.chat",
        component: () => import("../views/app/user/chat/Chat"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/channel/:chat_id",
        name: "user.own.channel",
        component: () => import("../views/app/user/chat/Chat"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/channels",
        name: "user.own.channels",
        component: () => import("../views/app/user/Channels"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/profile",
        name: "user.own.profile.view",
        component: () => import("../views/app/user/profile/Profile"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/profile/info",
        name: "user.own.profile.info",
        component: () => import("../views/app/user/profile/Info"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/profile/wallet",
        name: "user.own.profile.wallet",
        component: () => import("../views/app/user/profile/Wallet"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/profile/wallet/add-card",
        name: "user.own.profile.wallet.addCard",
        component: () => import("../views/app/user/profile/Wallet/AddCard"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/profile/wallet/withdraw",
        name: "user.own.profile.wallet.withdraw",
        component: () => import("../views/app/user/profile/Wallet/Withdraw"),
        meta: {
            middleware: [auth, verified],
        },
    },
    {
        path: "/profile/wallet/top-up",
        name: "user.own.profile.wallet.topUp",
        component: () => import("../views/app/user/profile/Wallet/TopUp"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/user/:id/story",
        name: "user.story",
        component: () => import("../views/app/story/StoryView"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/user/setting/tips",
        name: "user.setting.tips",
        component: () =>
            import("../views/app/user/profile/creator/TipsSetting"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/user/:id/tips",
        name: "user.tips.view",
        component: () => import("../views/app/user/UserTipMenu"),
    },
    {
        path: "/user/setting/main",
        name: "user.setting",
        component: () => import("../views/app/user/Setting"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/user/setting/password",
        name: "user.setting.password",
        component: () => import("../views/app/user/setting/Password"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/user/setting/email",
        name: "user.setting.email",
        component: () => import("../views/app/user/setting/Email"),
        meta: {
            middleware: [auth],
        },
    },
    // {
    //     path: '/user/setting/notification',
    //     name: 'user.setting.notification',
    //     component: () => import('../views/app/user/setting/Notification'),
    //     meta: {
    //         middleware: [auth, email_verified]
    //     }
    // },
    {
        path: "/user/setting/blacklist",
        name: "user.setting.blacklist",
        component: () => import("../views/app/user/setting/BlockedUser"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/user/setting/block-regions",
        name: "user.setting.blockRegions",
        component: () => import("../views/app/user/setting/BlockRegions"),
    },
    {
        path: "/user/setting/language",
        name: "user.setting.language",
        component: () => import("../views/app/user/setting/Language"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/user/setting/contact",
        name: "user.setting.contact",
        component: () => import("../views/app/user/setting/ContactUs"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/user/setting/deleteAccount",
        name: "user.setting.delete_account",
        component: () => import("../views/app/user/setting/DeleteAccount"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/user/setting/logout",
        name: "user.setting.logout",
        component: () => import("../views/app/user/setting/Logout"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/user/setting/sessions",
        name: "user.setting.sessions",
        component: () => import("../views/app/user/setting/Sessions"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/user/setting/creator",
        name: "user.setting.creator",
        component: () => import("../views/app/user/profile/creator/Creator"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/user/setting/creator/tips",
        name: "user.setting.creator.tips",
        component: () =>
            import("../views/app/user/profile/creator/TipsSetting"),
        meta: { middleware: [auth, email_verified, verified] },
    },

    {
        path: "/user/setting/creator/verify-ondato",
        name: "user.setting.creator.verifyOndato",
        component: () =>
            import("../views/app/user/profile/creator/VerifyOndato"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/user/setting/creator/agreement",
        name: "user.setting.creator.agreement",
        component: () => import("../views/app/user/profile/creator/Agreement"),
        meta: {
            middleware: [auth, email_verified, ondato_verified],
        },
    },
    {
        path: "/user/setting/creator/verified",
        name: "user.setting.creator.verified",
        component: () => import("../views/app/user/profile/creator/Verified"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/user/setting/creator/messages",
        name: "user.setting.creator.messages",
        component: () => import("../views/app/user/profile/creator/Messages"),
        meta: { middleware: [auth, email_verified, verified] },
    },
    {
        path: "/user/setting/creator/referrals",
        name: "user.setting.creator.referrals",
        component: () => import("../views/app/user/profile/creator/Referrals"),
        meta: { middleware: [auth, email_verified, verified] },
    },
    {
        path: "/user/setting/creator/promotion",
        name: "user.setting.creator.promotion",
        component: () => import("../views/app/user/profile/creator/Promotion"),
        meta: { middleware: [auth, email_verified, verified] },
    },
    {
        path: "/user/setting/creator/streams",
        name: "user.setting.creator.streams",
        component: () =>
            import("../views/app/user/profile/creator/StreamSettings.vue"),
        meta: { middleware: [auth, email_verified, verified] },
    },
    {
        path: "/user/setting/creator/subscription",
        name: "user.setting.creator.subscription",
        component: () =>
            import("../views/app/user/profile/creator/Subscriptions"),
        meta: { middleware: [auth, email_verified, verified] },
    },
    {
        path: "/profile/my-subscriptions",
        name: "user.profile.mySubscriptions",
        component: () =>
            import("../views/app/user/profile/subscriptions/Subscriptions.vue"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/user/setting/creator/withdraw",
        name: "user.setting.creator.withdraw",
        component: () => import("../views/app/user/profile/creator/Withdraw"),
        meta: { middleware: [auth, email_verified, verified] },
    },
    {
        path: "/user/setting/creator/withdraw/bank",
        name: "user.setting.creator.withdraw.bank",
        component: () =>
            import("../views/app/user/profile/creator/withdraw/BankTransfer"),
        meta: { middleware: [auth, email_verified, verified] },
    },
    {
        path: "/user/setting/creator/withdraw/bitsafe",
        name: "user.setting.creator.withdraw.bitsafe",
        component: () =>
            import("../views/app/user/profile/creator/withdraw/Bitsafe"),
        meta: { middleware: [auth, email_verified, verified] },
    },
    {
        path: "/user/setting/creator/withdraw/international-wire-transfer",
        name: "user.setting.creator.withdraw.internationalWireTransfer",
        component: () =>
            import("../views/app/user/profile/creator/withdraw/InternationalWireTransfer"),
        meta: { middleware: [auth, email_verified, verified] },
    },
    {
        path: "/user/setting/creator/withdraw/crypto-wallet",
        name: "user.setting.creator.withdraw.cryptoWallet",
        component: () =>
            import("../views/app/user/profile/creator/withdraw/CryptoWallet"),
        meta: { middleware: [auth, email_verified, verified] },
    },
    {
        path: "/user/:id/followers",
        name: "user.profile.followers",
        component: () => import("../views/app/user/profile/Followers"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/user/:id/following",
        name: "user.profile.following",
        component: () => import("../views/app/user/profile/Following"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/user/verified",
        name: "user.verified",
        component: () => import("../views/app/user/profile/Following"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/user/:slug/stream/:token",
        name: "stream",
        component: () => import("../views/app/user/stream/Stream.vue"),
        meta: {
            middleware: [auth, email_verified],
        },
    },
    {
        path: "/paypal/payment-success",
        name: "topup.paypal.success",
        component: () => import("../views/app/user/payment/paypal/Success.vue"),
        meta: {
            middleware: [auth, email_verified],
        },
    },

    {
        path: "/paypal/payment-cancel",
        name: "topup.paypal.cancel",
        component: () => import("../views/app/user/payment/paypal/Cancel.vue"),
        meta: {
            middleware: [auth, email_verified],
        },
    },

    {
        path: "/feedback",
        name: "feedback.canny",
        component: () => import("@/components/app/common/CannyFeedback.vue"),
        meta: {
            middleware: [auth, email_verified],
        },
    }
];
