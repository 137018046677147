import { getCookie } from "@/helpers/functions";
import { LINK_ACCOUNT_KEY } from "@/configs/constants";

export default async function guest ({ next  }){
    const token = await getCookie('auth_token_default')
    const switch_token = await getCookie(LINK_ACCOUNT_KEY)
    if(token && !switch_token){
        return next({
            name: 'auth.home'
        })
    }
    return next()
}
