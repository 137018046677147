export default {
    // https://noodzly.com/app/home
    app: {
        home: {
            subscribed: "Subscribed",
            following: "Following",
            discover: "Discover",
        },
    },
    user: {
        setting: {
            main: {
                //https://noodzly.com/user/setting/main
                title: "Settings",
                delete_account: "Delete Account",
                logout: "Logout",
            },
            language: {
                //https://noodzly.com/user/setting/language
                title: "Language",
                button: "Done",
            },
            password: {
                //https://noodzly.com/user/setting/password
                title: "Password",
                old_password: "Old password",
                new_password: "New password",
                confirm_password: "Confirm password",
                update: "Update",
            },
            email: {
                //https://noodzly.com/user/setting/email
                title: "Email",
                email: "Email",
                example: "example@example.com",
                update: "Update",
            },
            //https://noodzly.com/user/setting/notification
            notification: {
                title: "Notifications",
                app: "App",
                follow: "Follow",
                new_subscriber: "New Subscriber",
                messages: "Messages",
                email: "Email",
                fund_sent: "Fund Sent",
                tips_received: "Tips Received",
                save: "Save",
            },
            //https://noodzly.com/user/setting/main
            blacklist: {
                title: "Blocked users",
            },
            //https://noodzly.com/user/setting/block-regions
            block_regions: {
                title: "Block Regions",
            },
            //https://noodzly.com/user/setting/sessions
            sessions: {
                title: "Sessions",
                something_went_wrong: "Something went wrong?",
                change_your_password: "Change your password",
                device: "Device",
                browser: "Browser",
                date: "Date",
            },
            //https://noodzly.com/user/setting/contact
            contact: {
                title: "Contact Us",
                placeholder: "What's Up?",
                send: "Send",
                sent: "Sent",
                type_label: "What brings you here?",
            },
            //https://noodzly.com/user/setting/creator
            creator: {
                title: "Noodzler",
                verify: {
                    title: "Verify Account",
                },
                agreement: {
                    title: "Agreement Contract",
                    sub_title:
                        "STANDARD AGREEMENT BETWEEN NOODZLY AND NOODZLER",
                    field: {
                        name: "Name",
                    },
                    btn: {
                        accept: "accept",
                    },
                },
                //https://noodzly.com/user/setting/creator/verify-ondato
                verify_ondato: {
                    title: "Verify Account by Ondato",
                    verify_account: "Verify Account",
                    action_link: "start verifying",
                    add_another_model: "Add Another Noodzler",
                    model_has_verified: "Noodzler {name} has been verified",
                    model_not_verified: "Noodzler {name} is not verified",
                    status_created: " ",
                    status_processed: "processed",
                    status_resolved: "resolved",
                    status_rejected: "rejected",
                },
                withdraw_options: {
                    title: "Withdraw Options",
                    withdraw: {
                        title: "Withdraw",
                        bank_transfer: {
                            title: "EUR Bank Transfer (SEPA)",
                            swift_code: "SWIFT Code",
                            placeholder_swift_code: "Insert SWIFT Code",
                            iban_number: "IBAN Number",
                            placeholder_iban_number: "Insert IBAN Number",
                            bank_name: "Bank Name",
                            placeholder_bank_name: "Type Bank Name",
                            bank_country: "Bank Country",
                            placeholder_bank_country: "Country",
                            convert_currency: "We will convert USD to EUR on the day of payment by {ecb_rate_link}",
                            note_1: "Time to receive payment: Sepa & Swift payments are now processed DAILY (Monday - Friday). Funds requested prior to 23:59 EST will be processed the following business day. Please allow an additional 5 - 7 business days for the funds to settle in your account.",
                            note_2: "NOTE: Your bank (or intermediary bank) may charge you fees in addition to the fees. Fees charged by your bank (or intermediary bank) are not under our control. Please consult with your bank prior to requesting a payment if you are unsure.",
                            beneficiary_name: 'Beneficiary Name',
                            placeholder_beneficiary_name: 'Insert Beneficiary Name',
                            beneficiary_street: 'Beneficiary Street',
                            placeholder_beneficiary_street: 'Insert Beneficiary Street',
                            beneficiary_city: 'Beneficiary City',
                            placeholder_beneficiary_city: 'Insert Beneficiary City',
                            beneficiary_country: 'Beneficiary Country',
                            placeholder_beneficiary_country: 'Insert Beneficiary Country',
                            beneficiary_state: 'Beneficiary State',
                            placeholder_beneficiary_state: 'Insert Beneficiary State',
                            beneficiary_zip: 'Beneficiary Zip',
                            placeholder_beneficiary_zip: 'Insert Beneficiary Zip',
                        },
                        bitsafe: {
                            title: "Yoursafe",
                            full_name: "Full Beneficiary Name",
                            iban: "YourSafe IBAN",
                            country: "Country",
                        },
                        wire_transfer: {
                            title: "International Wire Transfer",
                            swift_code: "SWIFT Code",
                            placeholder_swift_code: "Insert SWIFT Code",
                            iban_number: "IBAN Number",
                            placeholder_iban_number: "Insert IBAN Number",
                            bank_name: "Bank Name",
                            placeholder_bank_name: "Type Bank Name",
                            bank_country: "Bank Country",
                            placeholder_bank_country: "Country",
                            convert_currency: "We will convert USD to EUR on the day of payment by {ecb_rate_link}",
                            note_1: "Time to receive payment: Sepa & Swift payments are now processed DAILY (Monday - Friday). Funds requested prior to 23:59 EST will be processed the following business day. Please allow an additional 5 - 7 business days for the funds to settle in your account.",
                            note_2: "NOTE: Your bank (or intermediary bank) may charge you fees in addition to the fees. Fees charged by your bank (or intermediary bank) are not under our control. Please consult with your bank prior to requesting a payment if you are unsure.",
                            beneficiary_name: 'Beneficiary Name',
                            placeholder_beneficiary_name: 'Insert Beneficiary Name',
                            beneficiary_street: 'Beneficiary Street',
                            placeholder_beneficiary_street: 'Insert Beneficiary Street',
                            beneficiary_city: 'Beneficiary City',
                            placeholder_beneficiary_city: 'Insert Beneficiary City',
                            beneficiary_country: 'Beneficiary Country',
                            placeholder_beneficiary_country: 'Insert Beneficiary Country',
                            beneficiary_state: 'Beneficiary State',
                            placeholder_beneficiary_state: 'Insert Beneficiary State',
                            beneficiary_zip: 'Beneficiary Zip',
                            placeholder_beneficiary_zip: 'Insert Beneficiary Zip',
                        },
                        crypto_wallet: {
                            title: "Crypto",
                            wallet_address: "Crypto Wallet Address",
                            placeholder_wallet_address: "Insert Crypto Wallet Address",
                            crypto_currency: "Crypto",
                            placeholder_crypto_currency: "Select Crypto",
                            country: "Country",
                            placeholder_country: "Select Country",
                        },
                    },
                },
                //https://noodzly.com/user/setting/creator/streams
                streams: {
                    title: "Private Streams",
                    tabs: {
                        now: "Now",
                        later: "Later",
                    },
                    fields: {
                        price: {
                            title: "Price",
                        },
                        ticketsSold: {
                            title: "Tickets Sold",
                        },
                        viewersLimit: {
                            title: "Viewers Limit",
                        },
                        dateTime: {
                            title: "Starts at",
                        },
                        isFreeForSubscribers: {
                            title: "Is free for subscribers",
                        },
                        rating: {
                            title: "Your streams scoring",
                        },
                        isFreeForFollowers: {
                            title: "Is free for followers",
                        },
                    },
                    actions: {
                        schedule: "Schedule",
                        save: "Save",
                        goLive: "Go Live",
                    },
                    rules: {
                        title: "By using live shows you agree to our ",
                        name: "stream rules",
                    },
                },
                stream: {
                    title: "Private Stream",
                    streamIsNotLive: "Stream is not live yet",
                    messages: {
                        system: {
                            joined: "joined",
                        },
                    },
                    liveReport: {
                        title: "Live Report",
                        totalTips: "Total Tips received",
                        totalTickets: "Total Tickets sold",
                        overall: "Overall",
                        totalTicketsInfo: "Revenue generated from ticket sales for your Stream will be credited to your wallet within 24 hours after the Stream concludes",
                    },
                    liveEnded: {
                        title: "Live Ended",
                        rate: {
                            options: {
                                bad: "Bad",
                                ok: "Ok",
                                good: "Good",
                                loveIt: "Love It",
                            },
                        },
                    },
                    streamingNow: "Streaming Now",
                    streamStartedOn: "Started {time} ago",
                },
                //https://noodzly.com/user/setting/creator/subscription
                subscriptions: {
                    title: "Subscriptions",
                    message_title:"(Direct Message)",
                    subscription_price: "Subscription price",
                    per_month: "Per month.",
                    welcome_message: "Welcome message",
                    welcome_message_rule:
                        "welcome message will be sent automatically to new subscribers",
                    welcome_audio_message_rule:
                        "* welcome audio message will be sent automatically to new subscribers",
                    audio_note:'Press and hold Mic to record the audio',
                    save: "Save",
                    subscribers: "Subscribers",
                    saved: "Done!",
                    text_message:"Message",
                    video_message:"Audio",
                    start_date:'Start date',
                    end_date:'End date',
                    renewal_date:'Renewal date',
                    total_earning:'Total Earnings',
                    total_spent:'Total Spent',
                    default_active_label:'Active',
                    subscription_default_message: 'Default Message',
                },
                //https://noodzly.com/user/setting/creator/tips
                tip_menu: {
                    title: "Tip Menu    ",
                    sub_title_1:
                        "Add items or services for sale.",
                    sub_title_2:
                        "Buyers can buy them directly from your profile.",
                    enter_name: "Add Item Name",
                    save: "Save",
                    saved: "Done!",
                    zero_price: "You can leave 0$ and it will be shown to buyer as 'On Request'",
                    on_request: "On request",
                },
                //https://noodzly.com/user/setting/creator/messages
                messages: {
                    title: "Messages",
                    direct_messages_price: "Direct messages price",
                    placeholder: "0-49.99",
                    save: "SAVE",
                    saved: "Done!",
                    price_suffix: "/Month",
                    direct_message_info: "User that don’t want to subscribe can message you paying this price"
                },

                referrals: {
                    title: "Referrals",
                    description:
                        "For everyone that signs up to Noodzly using your referral code you will receive a bonus equal to 3% of their net earnings for a 12 month period. This bonus is paid to you at the same time as they withdraw their earnings",
                    code: "Code",
                    links: "Links",
                    my_referrals: "My referrals",
                    name: "Name",
                    status: "Status",
                    date: "Date",
                },

                promotion: {
                    title: "Promotion",
                    description:
                        "Promotion enable you to boost your content in the “Discover” section and reach more people that can turn into new subscribers. Before starting your promotion, you must have at least one content in your story.",
                    notags: "Please select at least one tag",
                },
            },
            ideas: "Ideas",
        },
        payment: {
            paypal: {
                payment_processing: "Payment processing...",
                payment_failed: "Payment failed",
                payment_success: "Payment success",
                payment_cancelled: "Payment cancelled",
            },
        },
    },
    profile: {
        //https://noodzly.com/profile
        main: {
            creator: "NOODZLER",
            info: "INFO",
            followers: "Followers",
            following: "Following",
            get_app: "GET THE APP",
        },
        //https://noodzly.com/profile/info
        info: {
            title: "Info",
            type_your_bio: "Your Bio",
            amazon_url: "Amazon URL",
            instagram_url: "Instagram URL",
            twitter_url: "X URL",
            my_age: "My Age",
            i_am: "My Sex",
            male: "Male",
            female: "Female",
            transgender: "Transgender",
            categories: "Categories",
        },
        wallet: {
            //https://noodzly.com/profile/wallet
            withdraw: {
                available_balance: "Available balance",
                total_net: "You will receive",
                total_gross: "Total Gross",
                total_net_info:
                    "30% fees is used to cover referral payments, hosting, support, and all other services for Noodzly",
                bank_transfer: "EUR Bank Transfer (SEPA)",
                bitsafe: "Yoursafe",
                wire_transfer: "International Wire Transfer",
                crypto_wallet: "Crypto",
                noodzly_fee: "Noodzly Fee",
                additional_fee: "Bank Fee",
                additinal_fee_info: "Bank Fee is used to cover payment processing",
                withdraw_btn: 'Withdraw',
            },
        },
        stream: {
            header: "'s Streams",
            title: "Next Private Stream",
            purchaseTicket: "Buy Ticket",
            startWatching: "Start Watching",
            streamingNow: "Streaming Now",
            streamStartedOn: "Started {time} ago",
        },
        streams: {
            rating: {
                title: "Streams Score",
                stats: {
                    streams: "Streams",
                    min: "min",
                    averageDuration: "Avg Duration",
                },
            },
        },
    },
    //https://noodzly.com/search
    search: {
        placeholder: "Search",
    },
    messages: {
        title: "Messages",
        search: "Search",
        turn_off_direct_messages: "Turn off direct messages",
    },
    channels: {
        title: "Broadcasts",
        yourChannel: "Your broadcast",
        description: {
            forEveryone:
                "Broadcast is a way to receive messages from your Noodzlers to be updated on their next thing!",
            forCreators:
                "Broadcast lets you send messages only to your subscribers to keep them posted on your next thing!",
        },
        other_user_broadcast: "{user_name}'s Broadcast",
        pinned_message: "Pinned message",
    },
    email_verify: {
        title: "Verify Email",
        almost_there: "Almost There!",
        before_link: "Hi {user}, we sent a confirmation email to",
        change: "(Change)",
        after_link: "to get started, confirm your email.",
        button: "RESEND EMAIL",
    },
    privacy: {
        title: "Privacy Policy",
        desc_1: "By using Noodzly.com's services you agree to our Cookies use. We use cookies for purposes including analytics, personalisation and ads.",
        desc_2: "This page and certain others on this site place and read third party cookies on your browser, which are used for non-essential purposes. Through these cookies, our partners collect personal data about you for their own purposes.",
    },
    home: {
        desktop_description:
            "Noodzly allows you to share your amateur content in total anonymity while getting paid and all content will be deleted after 24 hours.",
        qr_code_text: "Scan the QR code",
        qr_code_sub_text: "Better on your smartphone",
    },
    //https://noodzly.com/user/5/tips
    user_tips: {
        title: "{name}’s Tip Menu",
        subtitle: "Items or services for sale by {name}",
        zero_price_text: "You want to request {item_name}?",
        buy_item_text: "You want to buy {tip_name} for {tip_price}$ ?",
    },
    copyright: "&copy;{year} Noodzly",
};
