export default {
    app: {
        home: {
            subscribed: "Iscritto",
            following: "Segui",
            discover: "Scopri",
        },
    },
    user: {
        setting: {
            main: {
                title: "Impostazioni",
                delete_account: "Elimina Account",
                logout: "Esci",
            },
            language: {
                title: "Lingua",
                button: "Fatto",
            },
            password: {
                title: "Password",
                old_password: "Vecchia password",
                new_password: "Nuova password",
                confirm_password: "Conferma password",
                update: "Aggiorna",
            },
            email: {
                title: "Email",
                email: "Email",
                example: "esempio@example.com",
                update: "Aggiorna",
            },
            notification: {
                title: "Notifiche",
                app: "App",
                follow: "Segui",
                new_subscriber: "Nuovo Iscritto",
                messages: "Messaggi",
                email: "Email",
                fund_sent: "Fondi Inviati",
                tips_received: "Fondi Ricevuti",
                save: "Salva",
            },
            blacklist: {
                title: "Utenti Bloccati",
            },
            block_regions: {
                title: "Blocca Paesi",
            },
            sessions: {
                title: "Sessioni",
                something_went_wrong: "Qualcosa è andato storto?",
                change_your_password: "Cambia la tua password",
                device: "Dispositivo",
                browser: "Browser",
                date: "Data",
            },
            contact: {
                title: "Contattaci",
                placeholder: "Cosa Succede?",
                send: "Invia",
                sent: "Inviato",
                type_label: "Cosa ti porta qui?",
            },
            creator: {
                title: "Noodzler",
                verify: {
                    title: "Verifica Account",
                },
                agreement: {
                    title: "Contratto di Accordo",
                    sub_title: "CONTRATTO STANDARD TRA NOODZLY E NOODZLER",
                    field: {
                        name: "Nome",
                    },
                    btn: {
                        accept: "accetta",
                    },
                },
                verify_ondato: {
                    title: "Verifica Account tramite Ondato",
                    verify_account: "Verifica Account",
                    action_link: "inizia la verifica",
                    add_another_model: "Aggiungi un altro Noodzler",
                    model_has_verified: "Il Noodzler {name} è stato verificato",
                    model_not_verified: "Il Noodzler {name} non è verificato",
                    status_created: " ",
                    status_processed: "elaborato",
                    status_resolved: "risolto",
                    status_rejected: "respinto",
                },
                withdraw_options: {
                    title: "Opzioni Prelievo",
                    withdraw: {
                        title: "Prelievo",
                        bank_transfer: {
                            title: "Bonifico Bancario",
                            swift_code: "Codice SWIFT",
                            placeholder_swift_code: "Inserisci il Codice SWIFT",
                            iban_number: "Codice IBAN",
                            placeholder_iban_number: "Inserisci il Codice IBAN",
                            bank_name: "Nome della Banca",
                            placeholder_bank_name:
                                "Inserisci il Nome della Banca",
                            bank_country: "Paese della Banca",
                            placeholder_bank_country: "Paese",
                            convert_currency: "Convertiremo USD in EUR il giorno del pagamento tramite {ecb_rate_link}",
                            note_1: "Tempo per ricevere il pagamento: i pagamenti Sepa & Swift vengono ora elaborati GIORNALMENTE (da lunedì a venerdì). I fondi richiesti entro le 23:59 EST verranno elaborati il giorno lavorativo successivo. Si prega di attendere ulteriori 5-7 giorni lavorativi per l'accredito dei fondi sul tuo conto.",
                            note_2: "NOTA: La tua banca (o banca intermedia) potrebbe addebitarti commissioni oltre alle tariffe. Le commissioni addebitate dalla tua banca (o banca intermedia) non sono sotto il nostro controllo. Consulta la tua banca prima di richiedere un pagamento se non sei sicuro.",
                            beneficiary_name: 'Nome del beneficiario',
                            placeholder_beneficiary_name: 'Inserisci il nome del beneficiario',
                            beneficiary_street: 'Via del beneficiario',
                            placeholder_beneficiary_street: 'Inserisci la via del beneficiario',
                            beneficiary_city: 'Città beneficiaria',
                            placeholder_beneficiary_city: 'Inserisci la città beneficiaria',
                            beneficiary_country: 'Paese beneficiario',
                            placeholder_beneficiary_country: 'Inserisci il Paese beneficiario',
                            beneficiary_state: 'Stato beneficiario',
                            placeholder_beneficiary_state: 'Inserisci lo Stato beneficiario',
                            beneficiary_zip: 'CAP del beneficiario',
                            placeholder_beneficiary_zip: 'Inserisci CAP beneficiario',
                        },
                        bitsafe: {
                            title: "Yoursafe",
                            full_name: "Nome Completo del Beneficiario",
                            iban: "Yoursafe IBAN",
                            country: "Paese",
                        },
                        wire_transfer: {
                            title: "Bonifico bancario internazionale",
                            swift_code: "Codice SWIFT",
                            placeholder_swift_code: "Inserisci il Codice SWIFT",
                            iban_number: "Codice IBAN",
                            placeholder_iban_number: "Inserisci il Codice IBAN",
                            bank_name: "Nome della Banca",
                            placeholder_bank_name: "Inserisci il Nome della Banca",
                            bank_country: "Paese della Banca",
                            placeholder_bank_country: "Paese",
                            convert_currency: "Convertiremo USD in EUR il giorno del pagamento tramite {ecb_rate_link}",
                            note_1: "Time to receive payment: Sepa & Swift payments are now processed DAILY (Monday - Friday). Funds requested prior to 23:59 EST will be processed the following business day. Please allow an additional 5 - 7 business days for the funds to settle in your account.",
                            note_2: "NOTE: Your bank (or intermediary bank) may charge you fees in addition to the fees. Fees charged by your bank (or intermediary bank) are not under our control. Please consult with your bank prior to requesting a payment if you are unsure.",
                            beneficiary_name: 'Nome del beneficiario',
                            placeholder_beneficiary_name: 'Inserisci il nome del beneficiario',
                            beneficiary_street: 'Via del beneficiario',
                            placeholder_beneficiary_street: 'Inserisci la via del beneficiario',
                            beneficiary_city: 'Città beneficiaria',
                            placeholder_beneficiary_city: 'Inserisci la città beneficiaria',
                            beneficiary_country: 'Paese beneficiario',
                            placeholder_beneficiary_country: 'Inserisci il Paese beneficiario',
                            beneficiary_state: 'Stato beneficiario',
                            placeholder_beneficiary_state: 'Inserisci lo Stato beneficiario',
                            beneficiary_zip: 'CAP del beneficiario',
                            placeholder_beneficiary_zip: 'Inserisci CAP beneficiario',
                        },
                        crypto_wallet: {
                            title: "Criptovaluta",
                            wallet_address: "Indirizzo del portafoglio crittografico",
                            placeholder_wallet_address: "Inserisci l'indirizzo del portafoglio crittografico",
                            crypto_currency: "Criptovaluta",
                            placeholder_crypto_currency: "Seleziona Cripto",
                            country: "Paese",
                            placeholder_country: "Seleziona Paese",
                        },
                    },
                },
                streams: {
                    title: "Streaming Privato",
                    tabs: {
                        now: "Ora",
                        later: "Programma",
                    },
                    fields: {
                        price: {
                            title: "Prezzo",
                        },
                        ticketsSold: {
                            title: "Biglietti venduti",
                        },
                        viewersLimit: {
                            title: "Limite Spettatori",
                        },
                        dateTime: {
                            title: "Inizia alle",
                        },
                        isFreeForSubscribers: {
                            title: "È gratuito per gli iscritti",
                        },
                        rating: {
                            title: "Punteggio dei tuoi stream",
                        },
                        isFreeForFollowers: {
                            title: "È gratuito per i follower",
                        },
                    },
                    actions: {
                        schedule: "Pianifica",
                        save: "Salva",
                        goLive: "Vai in diretta",
                    },
                    rules: {
                        title: "Utilizzando gli spettacoli dal vivo accetti i nostri ",
                        name: "regole del flusso",
                    },
                },
                stream: {
                    title: "Streaming Privato",
                    streamIsNotLive: "Lo streaming non è ancora live",
                    messages: {
                        system: {
                            joined: "entrato",
                        },
                    },
                    liveReport: {
                        title: "Informe en Vivo",
                        totalTips: "Total de Propinas Recibidas",
                        totalTickets: "Total de Entradas Vendidas",
                        overall: "Total",
                        totalTicketsInfo: "I ricavi generati dalla vendita dei biglietti per il tuo Streaming verranno accreditati sul tuo portafoglio entro 24 ore dalla conclusione dello Streaming",
                    },
                    liveEnded: {
                        title: "En Vivo Finalizado",
                        rate: {
                            options: {
                                bad: "Scarso",
                                ok: "Ok",
                                good: "Buono",
                                loveIt: "Lo Adoro",
                            },
                        },
                    },
                    rules: {
                        title: "Utilizzando spettacoli dal vivo accetti le nostre ",
                        name: "regole di streaming",
                    },
                    streamingNow: "In streaming adesso",
                    streamStartedOn: "Iniziato {time} fa",
                },
                subscriptions: {
                    title: "Iscrizioni",
                    message_title:"(Messaggio diretto)",
                    subscription_price: "Prezzo dell'iscrizione",
                    per_month: "Al mese.",
                    welcome_message: "Messaggio di benvenuto",
                    welcome_message_rule:
                        "il messaggio di benvenuto verrà inviato automaticamente ai nuovi abbonati",
                    welcome_audio_message_rule:
                        "* il messaggio audio di benvenuto verrà inviato automaticamente ai nuovi abbonati",
                    audio_note:"Tieni premuto il microfono per registrare l'audio",
                    save: "Salva",
                    subscribers: "Iscritti",
                    saved: "Fatto!",
                    text_message:"Messaggio",
                    video_message:"Audio",
                    start_date:'Data di rinnovo',
                    end_date:'Data di fine',
                    renewal_date:'Fecha de renovación',
                    total_earning:'Guadagni totali',
                    total_spent:'Totale speso',
                    default_active_label:'Attivo',
                    subscription_default_message:'Audio attivo',
                },
                tip_menu: {
                    title: "Menu dei Suggerimenti",
                    sub_title_1:
                        "Aggiungi articoli o servizi in vendita. Gli acquirenti possono acquistarli direttamente dal tuo profilo.",
                    sub_title_2:
                        "Avrai 3 giorni per consegnare la richiesta dall'acquirente.",
                    enter_name: "Aggiungi Nome dell'Articolo",
                    save: "Salva",
                    saved: "Fatto!",
                    zero_price: "Se lasci il prezzo a 0$, verrà visualizzato come 'Su richiesta'",
                    on_request: "Su richiesta",
                },
                messages: {
                    title: "Messaggi",
                    direct_messages_price: "Prezzo dei messaggi diretti",
                    placeholder: "0-49.99",
                    save: "SALVA",
                    saved: "Fatto!",
                    price_suffix: "/Mese",
                    direct_message_info: "L'utente che non vuole abbonarsi può inviarti un messaggio pagando questo prezzo"
                },
                referrals: {
                    title: "Referral",
                    description:
                        "Per chiunque si iscriva a Noodzly utilizzando il tuo codice di riferimento, riceverai un bonus pari al 3% dei loro guadagni netti per un periodo di 12 mesi. Questo bonus ti verrà pagato allo stesso tempo in cui ritirano i loro guadagni.",
                    code: "Codice",
                    links: "Legame",
                    my_referrals: "I Miei Referrals",
                    name: "Nome",
                    status: "Stato",
                    date: "Data",
                },
                promotion: {
                    title: "Promozione",
                    description:
                        'La promozione ti consente di potenziare i tuoi contenuti nella sezione "Scopri" e di raggiungere più persone che possono trasformarsi in nuovi abbonati. Prima di iniziare la tua promozione, devi avere almeno un contenuto nella tua storia.',
                    notags: "Seleziona almeno un tag",
                },
            },
            ideas: "Idee",
        },
        payment: {
            paypal: {
                payment_processing: "Elaborazione dei pagamenti...",
                payment_failed: "Pagamento non riuscito",
                payment_success: "Pagamento riuscito",
                payment_cancelled: "Pagamento annullato",
            },
        },
    },
    profile: {
        main: {
            creator: "NOODZLER",
            info: "INFO",
            followers: "Seguaci",
            following: "Seguiti",
            get_app:"Obtener la aplicación",
        },
        info: {
            title: "Informazioni",
            type_your_bio: "La tua Bio",
            amazon_url: "URL di Amazon",
            instagram_url: "URL di Instagram",
            twitter_url: "URL di X",
            my_age: "La mia età",
            i_am: "Il mio sesso",
            male: "Maschio",
            female: "Femmina",
            transgender: "Transgender",
            categories: "Categorie",
        },
        wallet: {
            withdraw: {
                available_balance: "Saldo disponibile",
                total_net: "Totale Netto",
                total_gross: "Totale Lordo",
                total_net_info:
                    "Il 30% delle commissioni viene utilizzato per coprire l'elaborazione dei pagamenti, i pagamenti di riferimento, l'hosting, il supporto e tutti gli altri servizi per Noodzly",
                bank_transfer: "Bonifico Bancario",
                bitsafe: "Yoursafe",
                wire_transfer: "Bonifico bancario internazionale",
                crypto_wallet: "Criptovaluta",
                noodzly_fee: "Tariffa Noodzly",
                additional_fee: "Commissione bancaria",
                additinal_fee_info: "La commissione bancaria viene utilizzata per coprire l'elaborazione dei pagamenti",
                withdraw_btn: 'Ritirare',
            },
        },
        stream: {
            header: "Dirette di ",
            title: "Prossima Diretta Privata",
            purchaseTicket: "Acquista Biglietto",
            startWatching: "Inizia a Guardare",
            streamingNow: "In streaming adesso",
            streamStartedOn: "Iniziato {time} fa",
        },
        streams: {
            rating: {
                title: "Valutazione delle Dirette",
                stats: {
                    streams: "Dirette",
                    min: "min",
                    averageDuration: "Durata Media",
                },
            },
        },
    },
    search: {
        placeholder: "Cerca",
    },
    messages: {
        title: "Messaggi",
        search: "Cerca",
        turn_off_direct_messages: "Disattiva i messaggi diretti",
    },
    channels: {
        title: "Trasmissioni",
        yourChannel: "La tua trasmissione",
        description: {
            forEveryone:
                "Broadcast è un modo per ricevere messaggi dai tuoi Noodles per essere aggiornato sulla loro prossima cosa!",
            forCreators:
                "La trasmissione ti permette di inviare messaggi solo ai tuoi iscritti per tenerli aggiornati sulle tue prossime novità!",
        },
        other_user_broadcast: "Trasmissione di {user_name}",
        pinned_message: "Messaggio bloccato",
    },
    email_verify: {
        title: "Verifica Email",
        almost_there: "Quasi arrivato!",
        before_link: "Ciao {user}, abbiamo inviato una email di conferma a",
        change: "(Cambia)",
        after_link: "per iniziare, conferma la tua email.",
        button: "REINVIA EMAIL",
    },
    privacy: {
        title: "Informativa sulla privacy",
        desc_1: "Utilizzando i servizi di Noodzly.com accetti l'uso dei nostri cookie. Utilizziamo i cookie per scopi tra cui analisi, personalizzazione e annunci.",
        desc_2: "Questa pagina e alcune altre su questo sito posizionano e leggono cookie di terze parti sul tuo browser, che vengono utilizzati per scopi non essenziali. Attraverso questi cookie, i nostri partner raccolgono dati personali su di te per i loro stessi scopi.",
    },
    home: {
        desktop_description:
            "Noodzly ti permette di condividere il tuo contenuto amatoriale in totale anonimato mentre ricevi un compenso e tutto il contenuto verrà cancellato dopo 24 ore.",
        qr_code_text: "Scansiona il codice QR",
        qr_code_sub_text: "Meglio sul tuo smartphone",
    },
    user_tips: {
        title: "Menu dei Suggerimenti di {name}",
        subtitle: "Articoli o servizi in vendita da {name}",
        zero_price_text: "Vuoi richiedere {item_name}?",
        buy_item_text: "Vuoi acquistare {tip_name} per {tip_price}$?",
    },
    copyright: "&copy;{year} Noodzly",
};
