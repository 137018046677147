export default {
    are_you_sure: "¿Estás seguro?",
    yes: "Sí",
    no: "No",
    back: "Atrás",
    copied_clipboard: "Copiado",
    filters: "Filtros",
    section: "Sección",
    reset: "Resetiar",
    subscribed: "Subscrito",
    following: "Siguidos",
    discover: "Descubrir",
    age: "Edad",
    sex: "Género",
    male: "Masculino",
    female: "Femenino",
    transgender: "Transgénero",
    save: "Guardar",
    add: "Agregar",
    tags: "Etiquetar",
    done: "Hecho",
    minimum_payment: "Pago Mínimo",
    receive_payment: "Días para Recibir Pago",
    check_out_more:"Confira mais",
    days: "Días",
    hours: "Horas",
    minutes: "Minutos",
    use_default: "Usar como predeterminado",

    unlock_verify_account: "Para desbloquear, verifica tu cuenta",
    messages_disabled: "Oops... Los mensajes están desactivados",
    not_contents: "Aún no tienes contenido",
    direct_message_title: "Mensaje Directo",
    direct_message_description: "Escribe un mensaje",
    subscribe: "SUSCRIBIRSE",
    month: "Mes",
    direct_message: "MENSAJE DIRECTO",
    balance_insufficient:
        "Oops... tu saldo es insuficiente. Puedes recargar haciendo clic en el botón de abajo",
    positive_balance_error_on_account_deletion:
        "Debes retirar tus fondos de la billetera antes de eliminar tu perfil",
    has_subscribers_error_on_account_deletion:
        "Tu cuenta se eliminará cuando tu último suscriptor expire en la fecha {renewal_at}, asegúrate de retirar todos tus fondos o tu cuenta no se eliminará",
    wallet: "billetera",
    report: "Reportar",
    report_placeholder: "Indícanos por qué quieres reportar",
    send: "Enviar",
    subscriptions: "Suscripciones",
    subscription_include: "La suscripción incluye:",
    active_until: "Activo hasta",
    support_model: "Apoya al Noodzler",
    unlock_all_stories: "Desbloquea todo el contenido",
    unlimited_direct_messages: "Mensajes Directos Ilimitados",
    unlock_content: "Desbloquear contenido por",
    confirm_ending_stream:
        "¿Estás seguro de que quieres finalizar la transmisión?",
    no_streams_scheduled: `{username} no tiene ninguna transmisión programada`,
    not_available_to_start_streaming:
        "La transmisión comenzará en {date}, en {difference}",
    streams_restricted: "Has sido suspendido",
    cancel_subscription: "Cancelación de suscripción",
    cancel_subscription_desc:
        "Tu suscripción finalizará en la próxima fecha de renovación y no se te cobrará.",
    your_must: "Debes",
    your_must_before_content:"Para mantener a Noodzly como un lugar más seguro, debes",
    verify_account: "verificar cuenta",
    unlock: "Desbloquear",
    update: "Actualizar",
    open: "Abrir",
    reset_password: "Restablecer Contraseña",
    password: "Contraseña",
    new_password: "Nueva Contraseña",
    verify_password: "Verificar Contraseña",
    forget_password: "Olvidé mi Contraseña",
    password_reset_confirmed: "Confirmación de Restablecimiento de Contraseña",
    error_token_email: "Se requiere el token y el correo electrónico.",
    email: "Correo Electrónico",
    write_something: "¿Qué hay de nuevo?",
    my_story: "MI HISTORIA",
    subscribers: "SUSCRIPTORES",
    chat: "CHAT",
    free: "Gratis",
    post: "PUBLICAR",
    tags_hint: "Agregar máximo 3 etiquetas",
    boost_tags_hint: "Agregar máximo 10 etiquetas",
    redo: "Rehacer",
    set_image: "Establecer Imagen",
    purchased: "COMPRADO",
    gives_you: "Te Envió",
    tips: "PROPINAS",
    total: "total",
    delete_story_hint:
        "No perderás tus ganancias, pero si alguien ya lo compró, se eliminará después de 24 horas desde la compra.",
    modify_content: "Modifica tu contenido",
    your_message: "Tu Mensaje",
    profile_link: "Enlace Perfil",
    unblock: "Desbloquear",
    block: "Bloquear",
    subscribe_for: "Suscribirse por",
    top_up: "Recargar",
    top_up_via_crypto: "Recargar a través de Cripto",
    top_up_via_card: "Recargar a través de Tarjeta",
    top_up_via_paypal: "Recarga a través de PayPal",
    top_up_warning: "* Se pueden aplicar IVA y tarifas de transacción",
    active: "Activo",
    expired: "Caducado",
    cancel: "Cancelar",
    renew: "Renovar",
    balance: "Saldo",
    withdraw: "Retirar",
    my_subscriptions: "Mis Suscripciones",
    from: "Desde",
    to: "Hasta",
    email_verify: "Verificar Correo Electrónico",

    or: "o",
    delete: "eliminar",
    unfollow: "dejar de seguir",
    something_is_missing: "Oops... Falta algo",
    to_turn_off_price:
        "para desactivar los mensajes, establece el precio en 0 en la",
    noodzlers_cant_turn_off_messages:
        "Los Noodzlers no pueden desactivar los mensajes",
    set_messages_price_error:
        "El precio de los mensajes no debe ser mayor al precio de la suscripción - ",
    set_subscription_price_error:
        "El precio de la suscripción debe ser mayor al precio del mensaje - ",
    messages_page: "página de mensajes",
    subscription_page: "página de suscripción",

    request: "Solicitar",
    send_funds: "{username} no puede recibir propinas.",
    countdown_text: "Comenzará una cuenta regresiva de 10 segundos para",
    photo: "Foto",
    video: "Video",

    access_camera_title: "Cámara y Micrófono",
    access_camera_description: "Noodzly quiere usar tu cámara y micrófono.",
    access_denied_camera_description:
        "Has bloqueado el acceso a la cámara y al micrófono, la aplicación no funcionará. Verifica la configuración de tu navegador.",
    deny: "Denegar",
    allow: "Permitir",
    accept: "Aceptar",
    confirm_your_password: "Para eliminar tu cuenta, confirma tu contraseña",
    password_placeholder: "Escribe tu contraseña",
    confirm_and_delete: "Confirmar y Eliminar",
    subscribe_for_free: "SUSCRIBIRSE GRATIS",
    has_no_tips: "Aún no hay ofertas",
    block_desktop: {
        only_mobile: "Por favor, utiliza la versión móvil",
        title: "Para una mejor experiencia, recomendamos usar la versión móvil. Utiliza la guía a continuación:",
        iphone: {
            title: "iPhone",
            list: [
                "Abre Safari en tu iPhone y ve a Noodzly.com",
                'Toca el icono de "Compartir" en la parte inferior. Parece un cuadro con una flecha hacia arriba.',
                'Selecciona el icono "Añadir a la pantalla de inicio".',
                "¡Disfruta!",
            ],
        },
        android: {
            title: "Android",
            list: [
                "Abre Google Chrome y ve a Noodzly.com",
                'Toca el icono de "Opciones". Puede parecerse a tres puntos verticales en la parte superior.',
                'Haz clic en "Añadir a la pantalla de inicio".',
                "¡Disfruta!",
            ],
        },
    },

    withdrawal_warning: {
        bank_transfer: {
            description: "El monto mínimo para retirar es de 100$",
        },
        bitsafe: {
            description: "El monto mínimo para retirar es de 50$",
        },
        wire_transfer: {
            description: "El monto mínimo a retirar es {min_amount}{currency}"
        },
        crypto_wallet: {
            description: "El monto mínimo a retirar es {min_amount}{currency}"
        },
    },

    withdrawal_form_error: {
        bank_transfer: {
            title: "Advertencia",
            description:
                "Agrega la información faltante en tu página de Transferencia Bancaria",
        },
        bitsafe: {
            title: "Advertencia",
            description:
                "Agrega la información faltante en tu página de Yoursafe",
        },
        wire_transfer: {
            title: "Advertencia",
            description: "Agregue la información que falta en su página de Transferencia Bancaria Internacional",
        },
        crypto_wallet: {
            title: "Advertencia",
            description: "Agrega la información que falta en tu página Crypto",
        },
    },
    no_content_warning: "Sube al menos un contenido en tu historia",
    content_expiring: "El tiempo de vencimiento de tus historias es menor que el tiempo de promoción.",
    filter_select: "Por favor seleccione al menos una sección",
    subscription_cancelled: "Suscripción cancelada exitosamente",
    renewal_message: "{user_name} cambió {gender} suscripción de {old_price} a {new_price}, ¿te gustaría renovar?",
    subscription: "Suscripción",
    max_price_error: "El precio debe estar entre {min_dm_price} y {max_dm_price}",
    transaction: 'Transacción',
    invalid_tip: 'La propina debe ser mayor que 0',
    referral_code_saved: 'Código de referencia guardado exitosamente',
    boost: {
        saved: 'Impulso creado exitosamente',
    },
    post_upload: {
        success: '¡Hurra! Éxito',
        failed: '¡Ups! Inténtalo de nuevo',
    },
    ok: 'Bueno',
    tap_back:'Toca Atrás',
    tap_next:'Toca Siguiente',
    pause:'Pausa',
    business_days: 'Días hábiles',
    fee: 'Tarifa',
    payment_method: {
        not_saved: "Los datos no se guardan, algo salió mal",
        saved: "Guardado",
    },
    video_guide: {
        swipe_gestures: "Presione y arrastre para navegar por el video",
    },
    contact_us: {
        form_title: "Contactar con el servicio de asistencia",
        email: "Correo electrónico",
        type_label: "¿Qué te trae por aquí?",
        question: {
            placeholder: "¿Qué pasa?"
        },
        send: "Enviar",
        sent: "Enviado",
    },
    switch_account: {
        add_account_label: "Agregar cuenta",
        login_to_account: "Iniciar sesión en una cuenta existente",
        create_account: "Crear una nueva cuenta",
    },
};
