import moment from "moment";

/**
 * Get label by code, or if there is no such code, return code from the array.
 *  [
 *  {code: 'user_id', label: 'User ID'},
 *  {code: 'user_name', label: 'User Name'},
 * ],
 * @param {array} array
 * @param {string} code
 * @returns {*}
 */
export function getLabelByCode(array, code) {
    const field = array.find((value) => {
        return code === value.code;
    });

    if (typeof field === "undefined" || !field.label) {
        return code;
    }

    return field.label;
}

/**
 * Checking is of type date or no, or if there is no such code, return false.
 *  [
 *  {code: 'created_at', label: 'Created Time', type: 'date'},
 *  {code: 'updated_at', label: 'Updated Time', type: 'date'},
 * ],
 * @param {array} array
 * @param {string} code
 * @returns {*}
 */
export function isDateByCode(array, code) {
    const field = array.find((value) => {
        return code === value.code;
    });

    return typeof field !== "undefined" && field.type === "date";
}

/**
 * Strip HTML tags from a string
 * @param {string} html
 * @returns {string|string}
 */
export function stripHTML(html) {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
}

/**
 * Check if browser is safari
 * @returns {boolean}
 */
export function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

/**
 * Check is fullscreen mode
 * @returns {boolean}
 */
export function isInFullScreen() {
    return (
        document.fullScreenElement ||
        document.mozFullScreen ||
        document.webkitIsFullScreen ||
        document.msIsFullScreen
    );
}

/**
 * Request fullscreen mode
 * @param open
 */
export function requestFullScreen(open = true) {
    if (open) {
        const element = document.documentElement;
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if (element.mozRequestFullscreen) {
            element.mozRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        } else if (element.msRequestFullScreen) {
            element.msRequestFullScreen();
        } else {
            console.log(typeof element.requestFullscreen === "function");
        }
    } else {
        if (document.cancelFullScreen) {
            document.cancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.msCancelFullscreen) {
            document.msCancelFullscreen();
        } else if (document.msCancelFullScreen) {
            document.msCancelFullScreen();
        }
    }

    if (typeof window.ActiveXObject !== "undefined") {
        try {
            /* global ActiveXObject */ // old IE
            let wscript = document.domain && new ActiveXObject("WScript.Shell");
            if (wscript !== null) {
                wscript.SendKeys("{F11}");
            }
        } catch (error) {
            /* ignore */
        }
    }
}

/**
 * Request fullscreen mode for safari
 * @param open
 */
export function safariRequestFullScreen(open = true) {
    // alert('standalone' in window.navigator)
    if (open && typeof document.webkitFullScreenElement === "undefined") {
        let element = document.body;
        // alert(element)
        if (element) {
            //alert(document.readyState)
            //alert('requestFullscreen' in element)
            //alert('requestFullScreen' in element)
            //alert('webkitRequestFullscreen' in element)
            //alert('webkitRequestFullScreen' in element)
            //alert('webkitRequestFullscreen' in document.documentElement)
            //
            //alert('webkitRequestFullScreen' in document.documentElement)
            //if (document.readyState === "complete") {
            //    alert('complete')
            //}
            // document.onreadystatechange = () => {
            //     alert('onreadystatechange')
            //     if (document.readyState == "complete") {
            //         alert('complete')
            //     }
            // }
            //
            // debounce(function () {
            //     this.$nextTick(() => {
            //         alert('debounce') // runs only once
            //         alert('webkitRequestFullscreen' in element) // runs only once
            //     })
            // }, 250)

            window.addEventListener(
                "DOMContentLoaded",
                function () {
                    alert("DOMContentLoaded");
                },
                false
            );

            //console.error('element founded')
            //element.webkitRequestFullscreen().then(data=> {
            //    screen.lockOrientationUniversal = screen.lockOrientation || screen.mozLockOrientation || screen.msLockOrientation;
            //    if (screen.lockOrientationUniversal("portrait")) {
            //        // Orientation was locked
            //        alert('Locked')
            //    } else {
            //        alert('Lock failed')
            //    }
            //}, err => {
            //    alert('Full Screen request failed');
            //});
        }
    } else if (document.webkitFullscreenElement) {
        document.webkitCancelFullScreen();
    }
}

/**
 * Open fullscreen mode
 */
export function openFullScreen() {
    //if (isSafari()) {
    if (!isInFullScreen()) {
        requestFullScreen();
        setTimeout(() => {
            try {
                if (isInFullScreen()) {
                    doLockOrientation();
                }
            } catch (error) {
                console.error(error.message);
            }
        }, 500);
    }
    //} else {
    //safariRequestFullScreen()
    //}
}

/**
 * Close fullscreen mode
 */
export function closeFullScreen() {
    //if (1) {
    if (isInFullScreen()) {
        screen.orientation.unlock();
        requestFullScreen(false);
    }
    // } else {
    // safariRequestFullScreen(false)
    // }
}

export function doLockOrientation() {
    try {
        if (
            screen.orientation &&
            typeof screen.orientation.lock === "function"
        ) {
            try {
                screen.orientation.lock("portrait");
            } catch (e) {
                console.log(e);
            }
        } else if (typeof screen.lockOrientation === "function") {
            screen.lockOrientation("portrait");
        } else if (typeof screen.mozLockOrientation === "function") {
            screen.mozLockOrientation("portrait");
        } else if (typeof screen.msLockOrientation === "function") {
            screen.msLockOrientation("portrait");
        } else if (
            window.orientation &&
            typeof window.orientation.lock === "function"
        ) {
            window.orientation.lock("portrait");
        } else {
            console.log("screen lock dont supported");
        }
    } catch (e) {
        console.log("screen lock dont supported");
    }
}

/**
 * Pluralization function for сyrillic languages
 * @param {number} choice a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
 * @param {number} choicesLength an overall amount of available choices
 * @returns {number} a final choice index to select plural word by
 **/
export function сyrillicPluralization(choice, choicesLength) {
    if (choice === 0) return 0;

    const teen = choice > 10 && choice < 20;
    const endsWithOne = choice % 10 === 1;

    if (choicesLength < 4) return !teen && endsWithOne ? 1 : 2;
    if (!teen && endsWithOne) return 1;
    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) return 2;

    return choicesLength < 4 ? 2 : 3;
}

export function hasCookie(name) {
    const value = `; ${document.cookie}`;
    return value.split(`; ${name}=`).length === 2;
}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(";").shift();
    }
}

export function setCookie(name, value, expHours) {
    let date = new Date();
    date.setTime(date.getTime() + expHours * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + "; " + expires + "; path=/";
}

export function removeCookie(name) {
    const expires = "expires=Thu, 01 Jan 1970 00:00:01 GMT";
    document.cookie = name + "=;" + expires + "; path=/";
}

export function parseDate(str) {
    var parts = str.split(/[T ]/);
    var dateparts = parts[0].split("-");
    var timeparts = (parts[1] || "").split(":");
    var year = +dateparts[0];
    var month = +dateparts[1];
    var day = +dateparts[2];
    var hours = timeparts[0] ? +timeparts[0] : 0;
    var minutes = timeparts[1] ? +timeparts[1] : 0;
    var seconds = timeparts[2] ? +timeparts[2] : 0;
    // Treats the string as UTC, but you can remove the `Date.UTC` part and use
    // `new Date` directly to treat the string as local time
    return new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
}

export function formatDate(dateInput) {
    const date = dateInput instanceof Date ? dateInput : parseDate(dateInput);
    return moment(date).format("MMM D YYYY hh:mm A");
}

export function timeSince(date) {
    const currentDate = moment();
    const startDate = moment(date);
    const duration = moment.duration(currentDate.diff(startDate));

    const hours = duration.hours();
    const minutes = duration.minutes();

    if (hours > 0) {
        return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} min${minutes > 1 ? 's' : ''}`;
    } else {
        return `${minutes} min${minutes > 1 ? 's' : ''}`;
    }
}

export function convertUTCDatetimeToLocal(utcDatetime) {
    const date =
        utcDatetime instanceof Date ? utcDatetime : parseDate(utcDatetime);
    const utc_offset = -(new Date().getTimezoneOffset() / 60);
    // date.setHours(date.getHours() + utc_offset);
    return date;
}

export function toLocalISOString(date) {
    const pad = (number) => (number < 10 ? "0" + number : number);

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());

    return `${year}-${month}-${day}T${hours}:${minutes}`;
}
